export const languageObject = {
  "Last Updated": "Last_Updated",
  "Assigned To": "Assigned_To",
  "Created At": "Created_At",
  "User Id": "User_Id",
  "User Name": "User_Name",
  "User Right": "User_Right",
  "Registration Type": "Registration_Type",
  "Company Name": "Company_Name",
  "Contact Person": "Contact_Person",
  "Trade Type": "Trade_Type",
  "Trade Level": "Trade_Level",
  "Registration Name": "Registration_Name",
  "Price ($)": "Price_In_Dollar",
  "Course Name": "Course_Name",
  "Start Date": "Start_Date",
  "End Date": "End_Date",
  "Class Timing": "Class_Timing",
  "Purchase Date": "Purchase_Date",
  "Trainer ID": "Trainer_ID",
  "Email ID": "Email_ID",
  "Certificate Number": "Certificate_Number",
  "Employee ID": "Employee_ID",
  "Join Date": "Join_Date",
  "Unit Price": "Unit_Price",
  "Discount(%)": "Discount_In_Percentage",
  "Customer Name": "Customer_Name",
  "Created on": "Created_on",
  "Employee Name": "Employee_Name",
  "Basic Salary": "Basic_Salary",
  "Net Salary": "Net_Salary",
  "Hours Worked": "Hours_Worked",
  "Overtime Hours": "Overtime_Hours",
  "Leave Type": "Leave_Type",
  "Course Title": "Course_Title",
  "Email Type": "Email_Type",
  "Mobile No": "Mobile_No",
  "Name of Participant": "Name_of_Participant",
  "Name Of Trainee": "Name_Of_Trainee",
  "NRIC/ FIN No": "NRIC_FIN_No",
  "Registration No / Submission No (CoreTrade / Multi-Skilling / Direct R1)":
    "Registration_No_Submission_No_CoreTrade_Multi_Skilling_Direct_R1",
  "Trainee's Attendance (Signature)": "Trainees_Attendance_Signature",
  "Results (P/f) *": "Results_P_f",
  "Gross Amount($)": "Gross_Amount_In_Dollar",
  "Quotation No": "Quotation_No",
  "Sales Quotation No": "Sales_Quotation_No",
  "Invoice Quotation No": "Invoice_Quotation_No",
  "Lec In Week": "Lec_In_Week",
  "Participant's Mobile": "Participants_Mobile",
  "Participant's Name": "Participants_Name",
  "Date Of Completion": "Date_Of_Completion",
  "CoreTrade Registration No": "CoreTrade_Registration_No",
  "Date Received": "date_Received",
  "Participant's Nric / FIN No.": "Participants_NRIC_FIN_No",
  Course: "Course",
  Category: "Category",
  Classes: "Classes",
  Trainer: "Trainer",
  Customers: "Customers",
  Name: "Name",
  User: "User",
  Role: "Role",
  Status: "Status",
  Mobile: "Mobile",
  Email: "Email",
  Seat: "Seat",
  Duration: "Duration",
  Amount: "Amount",
  DOB: "DOB",
  Designation: "Designation",
  Date: "Date",
  Time: "Time",
  Grade: "Grade",
  Attachment: "Attachment",
  Remarks: "Remarks",
  Position: "Position",
  Phone: "Phone",
  Department: "Department",
  Quantity: "Quantity",
  Allowances: "Allowances",
  Deductions: "Deductions",
  Shift: "Shift",
  Courses: "Courses",
  Completed: "Completed",
  Subject: "Subject",
  "Company Name": "Company_Name",
  "Contact Person": "Contact_Person",
  "Participant Name": "Participant_Name",
  Select: "Select",
};
